import React from "react"

import Layout from "../components/Layouts/Layout"
import SEO from "../components/SEO"

const description = "Creative Ilk is your guide to all things email marketing. Learn about digital marketing, use our CSS inliner, run A/B tests, and more. Get creative with us."

const NotFoundPage = () => (
  <Layout title="NOT FOUND">
    <SEO title="404: Not found" description={description} />
    <p>This isn't the page you're looking for...</p>
  </Layout>
)

export default NotFoundPage
